
import Popup from "@/components/popups/Popup.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import TableSelect from "@/components/atomics/TableSelect.vue";

import { defineComponent, onMounted, reactive, toRefs } from "vue";
import {
  AccountRepository,
  MemberRepository,
  RepositoryFactory,
} from "@/lib/https";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "AddSettingSignerPopup",
  components: {
    FlatButton,
    Popup,
    TableSelect,
  },
  emits: ["addAuthUser"],
  props: {
    numberOfCurrentUsers: { type: Number, default: 0 },
    selectedValues: { type: Array, default:[] as never[] },
  },
  setup(props) {
    const route = useRoute();
    const state = reactive({
      isOverflow: false,
      userID: route.params["userID"] as string,
      users: [],
      selectedUsers: [] as any,
      authUsers: [] as any,
    });

    const { addApprovers, deleteApprovers } =
      RepositoryFactory.getRepository<AccountRepository>(AccountRepository);
    const { getApproversList } = memberProfileAction();

    onMounted(async () => {
      state.users = (await getApproversList(1, 100, "", "")).data;
      state.selectedUsers =  [...props.selectedValues];
    });

    return {
      ...toRefs(state),
      deleteApprovers,
      addApprovers,
      getApproversList,
    };
  },
  methods: {
    updateSelectedUser(payload: any): void {
      this.selectedUsers = payload;
    },
    async addAuthUser() {
      this.selectedUsers.forEach((user: any) => {
        if (!this.authUsers.includes(user)) this.authUsers.push(user);
      });
      const authUserIds = this.authUsers.map((item: any) => item["id"]);
      await this.addApprovers(this.userID, { approvers: authUserIds as [] });
      this.$emit("addAuthUser");
    },
    removeAuthUser(user: any) {
      const index = this.authUsers.indexOf(user as never);
      this.authUsers.splice(index, 1);
    },

    async onSearchChange(keyword: string) {
      this.users = (
        await this.getApproversList(1, 100, keyword, this.userID)
      ).data;
    },

    onUpdateLayoutTableSelect() {
      const height = window.innerHeight;
      const popupContainer = this.$refs.popup as HTMLElement;
      const clientHieght = popupContainer?.clientHeight as number;
      if (clientHieght >= height - 210) {
        this.isOverflow = true;
      } else {
        this.isOverflow = false;
      }
    },
  },
});

const memberProfileAction = () => {
  const { getApproversList } =
    RepositoryFactory.getRepository<MemberRepository>(MemberRepository);
  return {
    getApproversList,
  };
};

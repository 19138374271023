
import { defineComponent, reactive, toRefs } from "vue";
import Popup from "@/components/popups/Popup.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import { RepositoryFactory, MemberRepository } from "@/lib/https";
import { useStore } from "vuex";
import { useForm } from "vee-validate";
import useFormHandler from "@/lib/compositional-logic/useFormHandler";

enum editPopupList {
  UPDATE_NAME,
  UPDATE_POSITION,
  UPDATE_PHONE_NUMBER,
  UPDATE_EMAIL,
}

export default defineComponent({
  name: "UpdateInfoPopup",
  components: {
    FlatButton,
    Popup,
    ValidationTextField,
  },
  props: {
    id: { type: String, required: true },
    showPopup: { type: Boolean, default: false },
    popupTitle: { type: String, required: true },
    popupListIndex: { type: Number, required: true },
    lastName: { type: String, default: "" },
    firstName: { type: String, default: "" },
    position: { type: String, default: "" },
    phoneNumber: { type: String, default: "" },
    email: { type: String, default: "" },
    isAccount: { type: Boolean, default: false },
    isPendingMember: { type: Boolean, default: false },
  },
  emits: ["hideEditPopup", "handleSubmitChangeField"],
  setup(props, context) {
    const state = reactive({
      id: props.id,
      popupIndex: props.popupListIndex,
      editLastName: props.lastName,
      editFirstName: props.firstName,
      editPosition: props.position,
      editPhoneNumber: props.phoneNumber,
      editEmail: props.email,
      editEmailSuccess: false,
    });
    const store = useStore();
    const accountInfo = store.getters["header/getAccountInfo"];
    const {
      updateFullName,
      updatePosition,
      updatePhoneNumber,
      updateEmail,
      updateNameInvitingMember,
      updateMailInvitingMember,
    } = RepositoryFactory.getRepository<MemberRepository>(MemberRepository);

    const form = useForm();
    const submitBtn = async function () {
      await formHandle();
    };
    const { formHandle } = useFormHandler(form, async () => {
      return submit();
    });

    const submit = async function () {
      if (props.isPendingMember) {
        if (state.editFirstName || state.editLastName) {
          const name = state.editLastName + " " + state.editFirstName;
          const response = await updateNameInvitingMember(name, state.id);
          if (response) {
            const requiredResponse = {
              lastName: state.editLastName,
              firstName: state.editFirstName,
            };
            context.emit("handleSubmitChangeField", requiredResponse);
          }
        } else if (state.editEmail) {
          const response = await updateMailInvitingMember(
            state.editEmail,
            state.id
          );
          const requiredResponse = {
            email: state.editEmail,
          };
          context.emit("handleSubmitChangeField", requiredResponse);
        }

        context.emit("hideEditPopup");

        return;
      }

      const formData = new FormData();
      if (state.editLastName || state.editFirstName) {
        formData.append("lastName", state.editLastName);
        formData.append("firstName", state.editFirstName);
        const response = await updateFullName(state.id, formData).catch((e) => {
          const requiredResponse = {
            errorMessage: e.data.message,
          };
          context.emit("handleSubmitChangeField", requiredResponse);
        });
        if (response) {
          const requiredResponse = {
            lastName: state.editLastName,
            firstName: state.editFirstName,
          };
          if (accountInfo.id == state.id) {
            await Promise.all([
              store.dispatch("header/setAccount", {
                fullName: state.editLastName + " " + state.editFirstName,
                avatar: accountInfo.avatar,
              }),
            ]);
          }
          context.emit("handleSubmitChangeField", requiredResponse);
        }
      } else if (state.editPosition) {
        formData.append("position", state.editPosition);
        const response = await updatePosition(state.id, formData).catch((e) => {
          const requiredResponse = {
            errorMessage: e.data.message,
          };
          context.emit("handleSubmitChangeField", requiredResponse);
        });
        if (response) {
          const requiredResponse = {
            position: state.editPosition,
          };
          context.emit("handleSubmitChangeField", requiredResponse);
        }
      } else if (state.editPhoneNumber) {
        formData.append("phoneNumber", state.editPhoneNumber);
        const response = await updatePhoneNumber(state.id, formData).catch(
          (e) => {
            const requiredResponse = {
              errorMessage: e.data.message,
            };
            context.emit("handleSubmitChangeField", requiredResponse);
          }
        );
        if (response) {
          const requiredResponse = {
            phoneNumber: state.editPhoneNumber,
          };
          context.emit("handleSubmitChangeField", requiredResponse);
        }
      } else if (state.editEmail) {
        formData.append("email", state.editEmail);
        let memberId = state.id;
        if (accountInfo.id == state.id) {
          memberId = "";
        }
        const response = await updateEmail(memberId, formData).catch((e) => {
          const requiredResponse = {
            errorMessage: e.data.message,
          };
          context.emit("hideEditPopup");
          context.emit("handleSubmitChangeField", requiredResponse);
        });

        if (response && props.isAccount == false) {
          const requiredResponse = {
            email: state.editEmail,
          };
          context.emit("handleSubmitChangeField", requiredResponse);
        } else if (response) {
          const requiredResponse = {
            emailPending: state.editEmail,
          };
          context.emit("handleSubmitChangeField", requiredResponse);
        }
      }

      if (state.editEmail && props.isAccount == true) {
        // Open My Account Edit Email Done Popup
        state.editEmailSuccess = true;
      } else {
        context.emit("hideEditPopup");
      }
    };

    return { ...toRefs(state), submitBtn, editPopupList };
  },
  methods: {
    hideEditPopup() {
      this.$emit("hideEditPopup");
    },
  },
});
/**
 * @vuese
 * @group Layout
 * Popup layout
 */

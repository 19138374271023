
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  computed,
  inject,
  onMounted,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import AfterLoginPageLayout from "@/views/layouts/AfterLoginPageLayout.vue";
import TextButton from "@/components/atomics/TextButton.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import AvatarCircle from "@/components/organisms/AvatarCircle.vue";
import BreadScrum from "@/components/atomics/BreadScrum.vue";
import MenuOptionPopup from "@/components/atomics/MenuOptionPopup.vue";
import Popup from "@/components/popups/Popup.vue";
import UpdateAvatarPopup from "@/components/popups/members/UpdateAvatarPopup.vue";
import UpdateInfoPopup from "@/components/popups/members/UpdateInfoPopup.vue";
import UpdatePasswordPopup from "@/components/popups/members/UpdatePasswordPopup.vue";
import AddSettingSignerPopup from "@/components/popups/members/AddSettingSignerPopup.vue";
import ChangeRolePopup from "@/components/popups/members/ChangeRolePopup.vue";
import { MemberRepository, RepositoryFactory } from "@/lib/https";
import { getFilePathFromUrl } from "@/lib/utility/stringUtil";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import Vue3Popper from "@/components/atomics/Vue3Popper.vue";

enum editPopupList {
  UPDATE_NAME,
  UPDATE_POSITION,
  UPDATE_PHONE_NUMBER,
  UPDATE_EMAIL,
  UPDATE_PASSWORD,
  UPDATE_LOGO,
}

export default defineComponent({
  name: "MemberProfile",
  components: {
    AfterLoginPageLayout,
    TextButton,
    FlatButton,
    AvatarCircle,
    BreadScrum,
    MenuOptionPopup,
    Popup,
    ChangeRolePopup,
    UpdateAvatarPopup,
    UpdateInfoPopup,
    UpdatePasswordPopup,
    AddSettingSignerPopup,
    Vue3Popper,
  },
  props: {
    userID: { type: String, default: "" },
    isInvitingMember: { type: String, default: "" },
  },
  setup(props) {
    const route = useRoute();
    const isMobile = inject("isMobile");
    const state = reactive({
      blocked: false,
      popupTitle: "",
      popupMessage: "",
      profile: {
        id: "",
        companyId: "",
        profilePicture: "",
        lastName: "",
        firstName: "",
        position: "",
        phoneNumber: "",
        email: "",
        emailVerified: "",
        role: 0,
        blocked: false,
        termsAgreed: "",
        receiveNotification: "",
        createdAt: "",
        canReset2FA: false,
        canUpdate: false,
      },
      showPopup: false,
      pictureFile: "",
      selectedEditPopup: -1,
      showAddAuthPopup: false,
      showChangeRolePopup: false,
      myRole: 0,
      myAccount: {},
    });

    const { t } = useI18n();
    const router = useRouter();
    const breadScrumLinks = ref([
      { text: t("memberProfile.teamMember"), to: "/team-member" },
      { text: t("memberProfile.memberProfile"), to: "#" },
    ]);

    const userID =
      props.userID == "" ? (route.params["userID"] as string) : props.userID;
    const isShownMenuOption = ref(false);
    const shownMenuOptionIndex = ref(-1);

    const store = useStore();
    state.myAccount = store.getters["header/getAccountInfo"];

    const userApprovers = ref();
    const groups = ref();
    const addApproversList = ref();
    const approverData = ref();

    const showTempLogo = computed(() => {
      if (state.pictureFile) {
        return URL.createObjectURL(state.pictureFile as any);
      }
      if (state.profile.profilePicture) {
        return state.profile.profilePicture;
      } else {
        return require("icons/default-avatar.svg");
      }
    });

    const showSuccessNotification = (text: string) => {
      store.commit("notification/showSuccessNotification", text);
    };

    const showErrorNotification = (text: string) => {
      store.commit("notification/showErrorNotification", text);
    };

    const {
      getMemberProfile,
      resetMember2FA,
      blockMember,
      unblockMember,
      deleteMember,
      getMemberGroupList,
      getApproversList,
      getInvitingMember,
      getInviteMemberList,
      pendingResend,
      pendingCancel,
      deleteApprovers,
      updateMemberRole,
      updateInviteMemberRole
    } = memberProfileAction();

    // Change owner
    const changeRole = async (role: number) => {
      const updateRolePromises = props.isInvitingMember == "true" ? updateInviteMemberRole : updateMemberRole; 
      const result = await updateRolePromises(state.profile.id, role)
        .then(() => {
          showSuccessNotification(t("notification.updateSuccess"));
          state.profile.role = role;
        })
        .catch((e) => {
          showErrorNotification(e.data.message);
        });
      state.showChangeRolePopup = false;
    };

    // Reset member 2FA
    const reset2FA = async () => {
      const result = await resetMember2FA(state.profile.id)
        .then(() => {
          showSuccessNotification(t("notification.updateSuccess"));
          state.profile.canReset2FA = false;
        })
        .catch((e) => {
          showErrorNotification(e.data.message);
        });
      state.popupTitle = "";
    };

    // Block member
    const blockAction = async () => {
      const result = await blockMember(state.profile.id)
        .then(() => {
          showSuccessNotification(t("notification.updateSuccess"));
          state.blocked = true;
        })
        .catch((e) => {
          showErrorNotification(e.data.message);
        });
      state.popupTitle = "";
    };

    // Unblock member
    const unblockAction = async () => {
      const result = await unblockMember(state.profile.id)
        .then(() => {
          showSuccessNotification(t("notification.updateSuccess"));
          state.blocked = false;
        })
        .catch((e) => {
          showErrorNotification(e.data.message);
        });
      state.popupTitle = "";
    };

    // Delete member
    const deleteAction = async () => {
      await deleteMember(state.profile.id)
        .then((result) => {
          if (result) {
            router.push({
              name: "TeamMember",
              params: {
                deleteItemSuccess: t("notification.deleteSuccess"),
              },
            });
          }
        })
        .catch((e) => {
          showErrorNotification(e.data.message);
        });
      state.popupTitle = "";
    };

    const resendAction = async () => {
      const result = await pendingResend(state.profile.id)
        .then(() => {
          showSuccessNotification(t("notification.updateSuccess"));
        })
        .catch((e) => {
          showErrorNotification(e.data.message);
        });
      state.popupTitle = "";
    };

    // Pending cancel
    const cancelAction = async (inviteMember: any) => {
      await pendingCancel(state.profile.id)
        .then((result) => {
          if (result) {
            router.push({
              name: "TeamMember",
              params: {
                deleteItemSuccess: t("notification.deleteSuccess"),
              },
            });
          }
        })
        .catch((e) => {
          showErrorNotification(e.data.message);
        });
    };

    const hideEditPopup = () => {
      state.selectedEditPopup = -1;
      state.showPopup = false;
    };

    const handleSubmitChangeField = ({ id, ...response }: any) => {
      state.profile = {
        ...state.profile,
        ...response,
      };
      if (response.profilePicture) {
        state.profile.profilePicture = response.profilePicture;
      }

      if (response.errorMessage) {
        showErrorNotification(response.errorMessage);
      } else {
        showSuccessNotification(t("notification.updateSuccess"));
      }
    };

    const updateSelectedApprover = async () => {
      const profilePayload = await getMemberProfile(userID);
      if (profilePayload) {
        userApprovers.value = profilePayload.approvers;
        state.showAddAuthPopup = false;
        showSuccessNotification(t("notification.createSuccess"));
      }
    };

    // Delete approver
    const deleteApprover = async (approver: any) => {
      const result = await deleteApprovers(userID, approver.id);
      if (result) {
        userApprovers.value.splice(userApprovers.value.indexOf(approver), 1);
        showSuccessNotification(t("notification.deleteSuccess"));
      }
    };

    onMounted(async () => {
      if (props.isInvitingMember == "true") {
        const invitingMember = await getInvitingMember(userID);
        state.profile.canUpdate = invitingMember.canUpdate;
        state.profile.id = invitingMember.id;
        state.profile.firstName = invitingMember.name ?? "";
        state.profile.email = invitingMember.email ?? "";
        state.profile.role = invitingMember.role;
        groups.value = invitingMember.groupsInfo;
      } else {
        const profilePayload = await getMemberProfile(userID).catch((e) => {
          router.push({ name: "TeamMember" });
        });
        if (profilePayload && Object.keys(profilePayload).length > 0) {
          state.profile = profilePayload;
          state.profile.profilePicture = profilePayload.profilePicture
            ? process.env.VUE_APP_API_CLOUD_URL + profilePayload.profilePicture
            : "";
          state.blocked = state.profile.blocked;
          const groupListPayload = await getMemberGroupList(userID);
          groups.value = groupListPayload.data;
          userApprovers.value = profilePayload.approvers;
          const approverPayload = await getApproversList(
            1,
            100,
            "",
            profilePayload.id
          );
          if (approverPayload) {
            addApproversList.value = approverPayload.data;
          }
        }
      }
    });

    return {
      ...toRefs(state),
      breadScrumLinks,
      isShownMenuOption,
      shownMenuOptionIndex,
      isMobile,
      editPopupList,
      groups,
      showTempLogo,
      userApprovers,
      addApproversList,
      approverData,
      hideEditPopup,
      reset2FA,
      blockAction,
      unblockAction,
      deleteAction,
      handleSubmitChangeField,
      deleteApprover,
      resendAction,
      cancelAction,
      updateSelectedApprover,
      changeRole,
      getFilePathFromUrl,
    };
  },
  computed: {
    getLogoData(): any {
      if (this.selectedEditPopup === editPopupList.UPDATE_LOGO)
        return this.profile.profilePicture;
      return "";
    },
  },
  methods: {
    showActionPopup(title: string, message: string) {
      this.popupTitle = title;
      this.popupMessage = message;
    },

    showEditPopup(selectedEditPopup: number) {
      this.selectedEditPopup = selectedEditPopup;
      this.showPopup = true;
    },

    hidePopup() {
      this.popupTitle = "";
      this.popupMessage = "";
    },
    onOptionOpen(index: number) {
      if (index == this.shownMenuOptionIndex) this.shownMenuOptionIndex = -1;
      else this.shownMenuOptionIndex = index;
    },

    navigationFolderGroup(group: any) {
      this.$router.push({
        name: "GroupFolder",
        query: {
          groupID: group.id,
          groupName: group.name,
        },
      });
    },
    navigationMemberGroup(group: any) {
      this.$router.push({
        name: "GroupMember",
        query: {
          groupID: group.id,
          groupName: group.name,
        },
      });
    },
    navigationTemplateGroup(group: any) {
      this.$router.push({
        name: "GroupTemplate",
        query: {
          groupID: group.id,
          groupName: group.name,
        },
      });
    },
  },
});

// Member Profile Actions
const memberProfileAction = () => {
  const {
    getMemberProfile,
    resetMember2FA,
    blockMember,
    unblockMember,
    deleteMember,
    getMemberGroupList,
    getApproversList,
    getInvitingMember,
    getInviteMemberList,
    pendingResend,
    pendingCancel,
    deleteApprovers,
    updateMemberRole,
    updateInviteMemberRole
  } = RepositoryFactory.getRepository<MemberRepository>(MemberRepository);
  return {
    getMemberProfile,
    resetMember2FA,
    blockMember,
    deleteApprovers,
    unblockMember,
    deleteMember,
    getMemberGroupList,
    getApproversList,
    getInvitingMember,
    getInviteMemberList,
    pendingResend,
    pendingCancel,
    updateMemberRole,
    updateInviteMemberRole
  };
};

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2651c4bc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "popup-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AvatarCircle = _resolveComponent("AvatarCircle")!
  const _component_TextButton = _resolveComponent("TextButton")!
  const _component_FlatButton = _resolveComponent("FlatButton")!
  const _component_popup = _resolveComponent("popup")!

  return (_ctx.showPopup)
    ? (_openBlock(), _createBlock(_component_popup, {
        key: 0,
        title: _ctx.$t('organizationDetail.editProfile'),
        onOnClickOutsidePopup: _ctx.hideEditPopup,
        onOnHide: _ctx.hideEditPopup
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", {
              class: "border-dashes-container",
              onDragleave: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"])),
              onDragover: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"])),
              onDrop: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.uploadImage && _ctx.uploadImage(...args)), ["prevent"]))
            }, [
              _createVNode(_component_AvatarCircle, {
                avatar: _ctx.showTempLogo,
                alt: "Symbol",
                class: "main-image"
              }, null, 8, ["avatar"]),
              _createElementVNode("input", {
                type: "file",
                ref: "image",
                accept: "image/png, image/jpeg",
                style: {"display":"none"},
                name: "avatar",
                onChange: _cache[0] || (_cache[0] = (e) => _ctx.uploadImage(e))
              }, null, 544),
              _createVNode(_component_TextButton, {
                icon: require('icons/camera.svg'),
                text: _ctx.$t('onboarding.logoText'),
                onOnClick: _ctx.uploadFile,
                class: "child"
              }, null, 8, ["icon", "text", "onOnClick"]),
              _createVNode(_component_TextButton, {
                icon: require('icons/delete-orange.svg'),
                text: _ctx.$t('onboarding.logoDeleteText'),
                onOnClick: _ctx.deleteImage,
                class: "child child-delete"
              }, null, 8, ["icon", "text", "onOnClick"])
            ], 32),
            (_ctx.selectedEditPopup === _ctx.editPopupList.UPDATE_LOGO)
              ? (_openBlock(), _createBlock(_component_FlatButton, {
                  key: 0,
                  text: _ctx.$t('organizationDetail.editConfirmButton'),
                  class: "confirm-button",
                  enabled: _ctx.enableBtn,
                  onClick: _ctx.submit_avatar
                }, null, 8, ["text", "enabled", "onClick"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }, 8, ["title", "onOnClickOutsidePopup", "onOnHide"]))
    : _createCommentVNode("", true)
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-59b35da9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "table-area" }
const _hoisted_2 = { class: "w-full section-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableSelect = _resolveComponent("TableSelect")!
  const _component_flat_button = _resolveComponent("flat-button")!
  const _component_popup = _resolveComponent("popup")!

  return (_openBlock(), _createBlock(_component_popup, {
    title: _ctx.$t('accountDetail.addAuthPopupTitle')
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: "popup-container",
        style: _normalizeStyle({ 'padding-bottom': _ctx.isOverflow ? '20px' : '4px' }),
        ref: "popup"
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_TableSelect, {
            class: "user-add-input",
            items: _ctx.users,
            selectedValues: _ctx.selectedValues,
            label: _ctx.$t('accountDetail.addAuthLabel'),
            "search-hint": _ctx.$t('accountDetail.addAuthSearchHint'),
            "is-create-tag": true,
            "limit-number": 5,
            limit: true,
            "is-enter-to-create-tag": false,
            onOnUpdate: _ctx.updateSelectedUser,
            onOnInput: _ctx.onSearchChange,
            onOnUpdateLayout: _ctx.onUpdateLayoutTableSelect
          }, null, 8, ["items", "selectedValues", "label", "search-hint", "onOnUpdate", "onOnInput", "onOnUpdateLayout"])
        ])
      ], 4),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_flat_button, {
          class: "w-full",
          onOnClick: _ctx.addAuthUser,
          text: _ctx.$t('accountDetail.addAuthButton'),
          enabled: _ctx.selectedUsers.length > 0
        }, null, 8, ["onOnClick", "text", "enabled"])
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}
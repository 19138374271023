
import Popup from "@/components/popups/Popup.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import Dropdown from "@/components/atomics/Dropdown.vue";

import { defineComponent, reactive, toRefs, watch } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "ChangeRolePopup",
  components: {
    FlatButton,
    Popup,
    Dropdown,
  },
  emits: ["onChangeRole"],
  props: {
    userId: { type: String, default: "" },
    currentRole: { type: Number, default: 1 },
    myRole: { type: Number, default: 1 },
    onChangeRole: { type: Function , default: null }
  },
  setup(props, context) {
    const { t } = useI18n();
    const state = reactive({
      selectedRole: props.currentRole,
      roleList: [
        { id: 1, name: t("teamMember.role1") },
        { id: 2, name: t("teamMember.role2") },
        { id: 3, name: t("teamMember.role3") },
      ],
      roleManagerList: [
        { id: 2, name: t("teamMember.role2") },
        { id: 3, name: t("teamMember.role3") },
      ],
    });

    const changeRole = async function () {
      await props.onChangeRole(state.selectedRole);
    };

    const onUpdateRole = function (id: any) {
      state.selectedRole = id;
    };

    return {
      ...toRefs(state),
      changeRole,
      onUpdateRole,
    };
  },
});

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-186e2f66"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "popup-container" }
const _hoisted_2 = { class: "text-popup" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "input-label" }
const _hoisted_5 = { class: "required-text" }
const _hoisted_6 = { class: "input-label mt-2" }
const _hoisted_7 = { class: "required-text" }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { class: "input-label" }
const _hoisted_11 = { class: "required-text" }
const _hoisted_12 = { class: "input-label mt-2" }
const _hoisted_13 = { class: "required-text" }
const _hoisted_14 = { class: "input-label mt-2" }
const _hoisted_15 = { class: "required-text" }
const _hoisted_16 = { class: "section-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ValidationPasswordField = _resolveComponent("ValidationPasswordField")!
  const _component_flat_button = _resolveComponent("flat-button")!
  const _component_popup = _resolveComponent("popup")!

  return (_ctx.showPopup)
    ? (_openBlock(), _createBlock(_component_popup, {
        key: 0,
        title: _ctx.popupTitle,
        onOnClickOutsidePopup: _ctx.hideEditPopup,
        onOnHide: _ctx.hideEditPopup
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              (!_ctx.isAccount)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t("common.requiredText")), 1),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t("organizationDetail.titlePassword")), 1)
                    ]),
                    _createVNode(_component_ValidationPasswordField, {
                      placeholder: _ctx.$t('organizationDetail.titlePassword'),
                      name: "password",
                      value: _ctx.password,
                      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.password) = $event)),
                      rules: "password|required"
                    }, null, 8, ["placeholder", "value"]),
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t("common.requiredText")), 1),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t("organizationDetail.retypePassword")), 1)
                    ]),
                    _createVNode(_component_ValidationPasswordField, {
                      placeholder: _ctx.$t('organizationDetail.retypePassword'),
                      name: "retypePassword",
                      value: _ctx.retypePassword,
                      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.retypePassword) = $event)),
                      "keep-error-space": false,
                      rules: "password|required"
                    }, null, 8, ["placeholder", "value"])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    (!_ctx.noPassword)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                          _createElementVNode("div", _hoisted_10, [
                            _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t("common.requiredText")), 1),
                            _createTextVNode(" " + _toDisplayString(_ctx.$t("accountDetail.oldPassword")), 1)
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    (!_ctx.noPassword)
                      ? (_openBlock(), _createBlock(_component_ValidationPasswordField, {
                          key: 1,
                          placeholder: _ctx.$t('accountDetail.oldPassword'),
                          name: "oldPassword",
                          value: _ctx.oldPassword,
                          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.oldPassword) = $event)),
                          "keep-error-space": false,
                          rules: "password|required"
                        }, null, 8, ["placeholder", "value"]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t("common.requiredText")), 1),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t("accountDetail.newPassword")), 1)
                    ]),
                    _createVNode(_component_ValidationPasswordField, {
                      placeholder: _ctx.$t('accountDetail.newPassword'),
                      name: "password",
                      value: _ctx.password,
                      "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.password) = $event)),
                      "keep-error-space": false,
                      rules: "password|required"
                    }, null, 8, ["placeholder", "value"]),
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.$t("common.requiredText")), 1),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t("accountDetail.retypePassword")), 1)
                    ]),
                    _createVNode(_component_ValidationPasswordField, {
                      placeholder: _ctx.$t('accountDetail.retypePassword'),
                      name: "retypePassword",
                      value: _ctx.retypePassword,
                      "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.retypePassword) = $event)),
                      "keep-error-space": false,
                      rules: "password|required"
                    }, null, 8, ["placeholder", "value"])
                  ]))
            ])
          ]),
          _createElementVNode("div", _hoisted_16, [
            (!_ctx.isAccount)
              ? (_openBlock(), _createBlock(_component_flat_button, {
                  key: 0,
                  text: _ctx.$t('organizationDetail.editConfirmButton'),
                  class: "confirm-button",
                  onClick: _ctx.submitPassword
                }, null, 8, ["text", "onClick"]))
              : (_openBlock(), _createBlock(_component_flat_button, {
                  key: 1,
                  text: _ctx.$t('organizationDetail.editConfirmButton'),
                  class: "confirm-button",
                  onClick: _ctx.changePassword
                }, null, 8, ["text", "onClick"]))
          ])
        ]),
        _: 1
      }, 8, ["title", "onOnClickOutsidePopup", "onOnHide"]))
    : _createCommentVNode("", true)
}
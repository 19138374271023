
import { defineComponent, reactive, toRefs, ref, watch } from "vue";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import MenuOptionDropdown from "@/components/atomics/MenuOptionDropdown.vue";
import { hasNumber, hasAlphabet } from "@/lib/utility/stringUtil";
import { useField, validate } from "vee-validate";
import { useI18n } from "vue-i18n";
import passwordOn from "@/assets/icons/password-on.svg";
import passwordOff from "@/assets/icons/password-off.svg";

export default defineComponent({
  components: {
    ValidationTextField,
    MenuOptionDropdown,
  },
  name: "ValidationPasswordField",
  props: {
    name: { type: String, default: "" },
    value: { type: String, default: "" },
    message: { type: Object || String },
    placeholder: { type: String, default: "" },
    rules: { default: "" },
    suffixIcon: { type: String, default: "" },
    keepErrorSpace: { type: Boolean, default: true },
  },
  emits: ["update:value", "onBlur"],
  setup(props) {
    const { t } = useI18n();
    const { setErrors } = useField(props.name, props.rules, {
      initialValue: props.value,
    });

    const validateState = reactive({
      val: props.value,
      hasCharacter: false,
      hasNumber: false,
      minimun8Chars: false,
      type: "password",
      passwordIcon: passwordOff,
    });

    const validatePassword = (validateState: any) => {
      if (isApplyFullRules(validateState)) {
        if (isHasBeenInputed(validateState))
          setErrors(t("errors.invalidPassword"));
      }
    };

    const isApplyFullRules = (validateState: any) =>
      !validateState.hasCharacter ||
      !validateState.hasNumber ||
      !validateState.minimun8Chars;

    const isHasBeenInputed = (validateState: any) =>
      validateState.hasCharacter || validateState.hasNumber;

    watch(
      () => props.value,
      (val, old) => {
        validateState.val = val;
        validatePassword(validateState);
      }
    );

    const hasFocus = ref(false);

    const checkPassword = () => {
      if (validateState.type == "password") {
        validateState.type = "text";
        validateState.passwordIcon = passwordOn;
      } else {
        validateState.type = "password";
        validateState.passwordIcon = passwordOff;
      }
    };

    return {
      ...toRefs(validateState),
      validatePassword,
      hasFocus,
      checkPassword,
    };
  },
  methods: {
    valueChanged(value: string) {
      if (hasAlphabet(value)) {
        this.hasCharacter = true;
      } else {
        this.hasCharacter = false;
      }

      if (hasNumber(value)) {
        this.hasNumber = true;
      } else {
        this.hasNumber = false;
      }

      if (value.length >= 8) {
        this.minimun8Chars = true;
      } else {
        this.minimun8Chars = false;
      }

      this.$emit("update:value", value);
    },

    onFocus() {
      this.hasFocus = true;
    },
    onBlur() {
      this.validatePassword({
        hasCharacter: this.hasCharacter,
        hasNumber: this.hasNumber,
        minimun8Chars: this.minimun8Chars,
      });
      this.hasFocus = false;
      this.$emit("onBlur");
    },
  },
});

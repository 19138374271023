import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b06c6276"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "popup-container" }
const _hoisted_2 = { class: "input-label" }
const _hoisted_3 = { class: "w-full section-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_flat_button = _resolveComponent("flat-button")!
  const _component_popup = _resolveComponent("popup")!

  return (_openBlock(), _createBlock(_component_popup, {
    title: _ctx.$t('teamMember.changeRolePopupTitle')
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("teamMember.permission")), 1),
        _createVNode(_component_Dropdown, {
          options: _ctx.myRole == 1 ? _ctx.roleList : _ctx.roleManagerList,
          "default-option": _ctx.selectedRole,
          onOnChange: _cache[0] || (_cache[0] = (roleId) => _ctx.onUpdateRole(roleId))
        }, null, 8, ["options", "default-option"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_flat_button, {
          class: "w-full",
          onClick: _ctx.changeRole,
          text: _ctx.$t('accountDetail.confirmButton'),
          enabled: _ctx.selectedRole != _ctx.currentRole
        }, null, 8, ["onClick", "text", "enabled"])
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}

import { defineComponent, reactive, toRefs, computed } from "vue";

import Popup from "@/components/popups/Popup.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import TextButton from "@/components/atomics/TextButton.vue";
import AvatarCircle from "@/components/organisms/AvatarCircle.vue";
import defaultAvatar from "@/assets/icons/default-avatar.svg";
import { RepositoryFactory, MemberRepository } from "@/lib/https";
import { useStore } from "vuex";
import { resizeImage } from "@/lib/utility/common";
import { useI18n } from "vue-i18n";
import { getFilePathFromUrl } from "@/lib/utility/stringUtil";

export default defineComponent({
  name: "UpdateAvatarPopup",
  components: {
    FlatButton,
    TextButton,
    Popup,
    AvatarCircle,
  },
  props: {
    showPopup: { type: Boolean, default: false },
    id: { type: String, required: true },
    avatar: { type: String, required: false },
    selectedEditPopup: { type: Number, required: true },
    editPopupList: { type: Object, required: true },
  },
  emits: ["handleSubmitChangeField", "hideEditPopup"],
  setup(props, context) {
    const store = useStore();
    const { t } = useI18n();
    const state = reactive({
      id: props.id,
      avatarFileTemp: "",
      memberAvatar: props.avatar,
      enableBtn: false,
    });

    const showTempLogo = computed(() => {
      if (state.avatarFileTemp) {
        handleEnableBtn();
        return URL.createObjectURL(state.avatarFileTemp as any);
      }
      if (state.memberAvatar && state.memberAvatar != 'default') {
        return getFilePathFromUrl(state.memberAvatar);
      } else {
        return defaultAvatar;
      }
    });

    const handleEnableBtn = () => {
      state.enableBtn = true;
    };

    const uploadImage = async (e: any) => {
      const sourceFile = e.target.files || e.dataTransfer.files;
      const file = sourceFile[0];
      if (file.size > 200000) {
        store.commit(
          "notification/showErrorNotification",
          t("errors.errorOversizeAvatar")
        );
        context.emit("hideEditPopup");
        return;
      }
      const imageResized = (await resizeImage(file)) as any;

      state.avatarFileTemp = imageResized.fileUpload;
    };

    const deleteImage = () => {
      state.avatarFileTemp = "";
      state.memberAvatar = "";
      if(props.avatar && props.avatar !== 'default'){
        handleEnableBtn();
      } else {
        state.enableBtn = false;
      }
    };

    const { updateProfilePicture } =
      RepositoryFactory.getRepository<MemberRepository>(MemberRepository);

    const submit_avatar = async function () {
      const formData = new FormData();
      formData.append("profilePicture", state.avatarFileTemp);
      const accountInfo = store.getters["header/getAccountInfo"];
      let response;
      if (state.id == accountInfo.id) {
        response = await updateProfilePicture("", formData).catch((e) => {
          const requiredResponse = {
            errorMessage: e.data.message,
          };
          context.emit("handleSubmitChangeField", requiredResponse);
        });
      } else {
        response = await updateProfilePicture(state.id, formData).catch((e) => {
          const requiredResponse = {
            errorMessage: e.data.message,
          };
          context.emit("handleSubmitChangeField", requiredResponse);
        });
      }

      const requiredResponse = {
        profilePicture: response.profilePicture
          ? process.env.VUE_APP_API_CLOUD_URL + response.profilePicture
          : defaultAvatar,
      };
      if (state.id == accountInfo.id) {
        await Promise.all([
          store.dispatch("header/setAccount", {
            avatar: response.profilePicture
              ? response.profilePicture +
                "?ver=" +
                new Date().getTime()
              : 'default',
          }),
        ]);
      }
      context.emit("handleSubmitChangeField", requiredResponse);

      context.emit("hideEditPopup");
    };

    return {
      ...toRefs(state),
      showTempLogo,
      uploadImage,
      deleteImage,
      submit_avatar,
    };
  },
  methods: {
    hideEditPopup() {
      this.$emit("hideEditPopup");
    },
    uploadFile() {
      let fileInput = this.$refs.image as HTMLInputElement;
      fileInput.click();
    },
  },
});
/**
 * @vuese
 * @group Layout
 * Popup layout
 */

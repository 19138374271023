import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "validation-password-field" }
const _hoisted_2 = {
  key: 0,
  class: "password-hint"
}
const _hoisted_3 = { class: "password-rule-tile" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "rule-text ml-1" }
const _hoisted_6 = { class: "password-rule-tile mt-1" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "rule-text ml-1" }
const _hoisted_9 = { class: "password-rule-tile mt-1" }
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "rule-text ml-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ValidationTextField = _resolveComponent("ValidationTextField")!
  const _component_MenuOptionDropdown = _resolveComponent("MenuOptionDropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ValidationTextField, {
      rules: _ctx.rules,
      type: _ctx.type,
      modelValue: _ctx.val,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.val) = $event)),
      name: _ctx.name,
      placeholder: _ctx.placeholder,
      "keep-error-space": false,
      "suffix-icon": _ctx.passwordIcon,
      "suffix-icon-action": _ctx.checkPassword,
      "onUpdate:value": _ctx.valueChanged,
      onOnFocus: _ctx.onFocus,
      onOnBlur: _ctx.onBlur,
      message: {
        error_required: _ctx.$t('errors.required'),
        error_format_password: _ctx.$t('errors.invalidPassword'),
      }
    }, null, 8, ["rules", "type", "modelValue", "name", "placeholder", "suffix-icon", "suffix-icon-action", "onUpdate:value", "onOnFocus", "onOnBlur", "message"]),
    (_ctx.hasFocus)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_MenuOptionDropdown, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("img", {
                  src: 
              _ctx.hasCharacter
                ? require('icons/password-valid-icon.svg')
                : require('icons/check-mark-grey.svg')
            ,
                  alt: "icon"
                }, null, 8, _hoisted_4),
                _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("common.passwordHintCharacter")), 1)
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("img", {
                  src: 
              _ctx.hasNumber
                ? require('icons/password-valid-icon.svg')
                : require('icons/check-mark-grey.svg')
            ,
                  alt: "icon"
                }, null, 8, _hoisted_7),
                _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t("common.passwordHintNumber")), 1)
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("img", {
                  src: 
              _ctx.minimun8Chars
                ? require('icons/password-valid-icon.svg')
                : require('icons/check-mark-grey.svg')
            ,
                  alt: "icon"
                }, null, 8, _hoisted_10),
                _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.$t("common.passwordHintMin")), 1)
              ])
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ef025a44"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "popup-container"
}
const _hoisted_2 = { class: "text-popup" }
const _hoisted_3 = {
  key: 0,
  class: "name-edit"
}
const _hoisted_4 = { style: {"width":"49%"} }
const _hoisted_5 = { class: "input-label" }
const _hoisted_6 = { style: {"width":"49%"} }
const _hoisted_7 = { class: "input-label" }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "input-label" }
const _hoisted_10 = { key: 2 }
const _hoisted_11 = { class: "input-label" }
const _hoisted_12 = { key: 3 }
const _hoisted_13 = { class: "input-label" }
const _hoisted_14 = {
  key: 1,
  class: "popup-container"
}
const _hoisted_15 = { class: "text-popup" }
const _hoisted_16 = ["innerHTML"]
const _hoisted_17 = { class: "w-full mg-confirm-btn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_validation_text_field = _resolveComponent("validation-text-field")!
  const _component_FlatButton = _resolveComponent("FlatButton")!
  const _component_flat_button = _resolveComponent("flat-button")!
  const _component_popup = _resolveComponent("popup")!

  return (_ctx.showPopup)
    ? (_openBlock(), _createBlock(_component_popup, {
        key: 0,
        title: _ctx.popupTitle,
        onOnClickOutsidePopup: _ctx.hideEditPopup,
        onOnHide: _ctx.hideEditPopup
      }, {
        default: _withCtx(() => [
          (_ctx.editEmailSuccess == false)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  (_ctx.popupIndex === _ctx.editPopupList.UPDATE_NAME)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createElementVNode("div", _hoisted_4, [
                          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("onboarding.lastNameHint")), 1),
                          _createVNode(_component_validation_text_field, {
                            placeholder: _ctx.$t('onboarding.lastNameHint'),
                            name: "lastName",
                            value: _ctx.editLastName,
                            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editLastName) = $event)),
                            rules: 'name|required',
                            message: {
                error_required: _ctx.$t('errors.required'),
                error_format_name: _ctx.$t('errors.formatName'),
              }
                          }, null, 8, ["placeholder", "value", "message"])
                        ]),
                        _createElementVNode("div", _hoisted_6, [
                          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t("onboarding.firstNameHint")), 1),
                          _createVNode(_component_validation_text_field, {
                            placeholder: _ctx.$t('onboarding.firstNameHint'),
                            name: "firstName",
                            value: _ctx.editFirstName,
                            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editFirstName) = $event)),
                            rules: 'name|required',
                            message: {
                error_required: _ctx.$t('errors.required'),
                error_format_name: _ctx.$t('errors.formatName'),
              }
                          }, null, 8, ["placeholder", "value", "message"])
                        ])
                      ]))
                    : (_ctx.popupIndex === _ctx.editPopupList.UPDATE_POSITION)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                          _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t("accountDetail.role")), 1),
                          _createVNode(_component_validation_text_field, {
                            placeholder: _ctx.$t('accountDetail.role'),
                            name: "position",
                            value: _ctx.editPosition,
                            "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editPosition) = $event)),
                            rules: 'required',
                            message: {
              error_required: _ctx.$t('errors.required'),
            }
                          }, null, 8, ["placeholder", "value", "message"])
                        ]))
                      : (_ctx.popupIndex === _ctx.editPopupList.UPDATE_PHONE_NUMBER)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                            _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t("organizationDetail.titlePhone")), 1),
                            _createVNode(_component_validation_text_field, {
                              placeholder: _ctx.$t('organizationDetail.titlePhone'),
                              name: "phoneNumber",
                              "max-length": 20,
                              value: _ctx.editPhoneNumber,
                              "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editPhoneNumber) = $event)),
                              rules: "number|required",
                              message: {
              error_required: _ctx.$t('errors.required'),
              error_format_number: _ctx.$t('errors.formatNumber'),
            }
                            }, null, 8, ["placeholder", "value", "message"])
                          ]))
                        : (_ctx.popupIndex === _ctx.editPopupList.UPDATE_EMAIL)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                              _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t("organizationDetail.titleEmail")), 1),
                              _createVNode(_component_validation_text_field, {
                                placeholder: _ctx.$t('organizationDetail.titleEmail'),
                                name: "email",
                                value: _ctx.editEmail,
                                "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editEmail) = $event)),
                                rules: "email|required",
                                "max-length": 320,
                                message: {
              error_required: _ctx.$t('errors.required'),
              error_format_email: _ctx.$t('errors.formatEmail'),
            }
                              }, null, 8, ["placeholder", "value", "message"])
                            ]))
                          : _createCommentVNode("", true),
                  _createVNode(_component_FlatButton, {
                    text: _ctx.$t('organizationDetail.editConfirmButton'),
                    class: "confirm-button",
                    "on-click": _ctx.submitBtn
                  }, null, 8, ["text", "on-click"])
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("p", {
                    innerHTML: 
            ` <span style='color: #17B182; font-weight: bolder'> ${_ctx.editEmail} </span>` +
            _ctx.$t('accountDetail.editEmailMessage')
          ,
                    class: "message"
                  }, null, 8, _hoisted_16),
                  _createElementVNode("div", _hoisted_17, [
                    _createVNode(_component_flat_button, {
                      text: _ctx.$t('accountDetail.completeButton'),
                      class: "add-btn",
                      onClick: _ctx.hideEditPopup
                    }, null, 8, ["text", "onClick"])
                  ])
                ])
              ]))
        ]),
        _: 1
      }, 8, ["title", "onOnClickOutsidePopup", "onOnHide"]))
    : _createCommentVNode("", true)
}
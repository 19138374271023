
import {
  defineComponent,
  onMounted,
  reactive,
  toRefs,
  ref,
  watch,
  inject,
} from "vue";
import Chip from "@/components/atomics/Chip.vue";
import Dropdown from "./Dropdown.vue";
import AvatarCircle from "@/components/organisms/AvatarCircle.vue";
import { getFilePathFromUrl } from "@/lib/utility/stringUtil";
import { useI18n } from "vue-i18n";
import Vue3Popper from "@/components/atomics/Vue3Popper.vue";

export default defineComponent({
  name: "TableSelect",
  components: {
    Chip,
    Dropdown,
    AvatarCircle,
    Vue3Popper,
  },
  props: {
    items: { type: Array, default: [] as never[] },
    selectedValues: { type: Array, default: [] as never[] },
    permissions: { type: Array, default: [] as never[] },
    itemTypes: { type: String, default: "User" },
    label: { type: String, default: "" },
    limit: { type: Boolean, default: false },
    limitNumber: { type: Number, default: Infinity },
    searchHint: { type: String, default: "" },
    isCreateTag: { type: Boolean, default: false },
    isEnterToCreateTag: { type: Boolean, default: true },
    isShowCompany: { type: Boolean, default: false },
    createUserWithTag: { type: Boolean, default: false },
    isRequired: { type: Boolean, default: false },
    rules: { type: String, default: "" },
    message: { type: Object, default: {} as never },
    defaultDropdrownOption: { default: "" },
  },
  emits: [
    "onSearchChange",
    "onUpdate",
    "onChange",
    "onChangePermission",
    "onUpdateLayout",
    "onInput",
  ],
  setup(props) {
    const state = reactive({
      selectedItems: [] as any,
      searchQuery: "",
      selectedPermission: "",
      items2: ref([]),
      inputFocused: false,
      itemRemoving: false,
    });

    const { t } = useI18n();

    const isMobile = inject("isMobile");

    watch(
      () => props.defaultDropdrownOption,
      (val) => {
        state.selectedPermission = val;
      }
    );

    watch(
      () => props.selectedValues,
      (val) => {
        state.selectedItems = val;
      }
    );

    onMounted(() => {
      state.selectedItems = [...props.selectedValues];
    });

    return { ...toRefs(state), getFilePathFromUrl, isMobile, t };
  },
  updated() {
    this.$nextTick(() => {
      this.$emit("onUpdateLayout");
    });
  },
  methods: {
    isSelectedItem(item: any): boolean {
      return (
        !!(this.selectedItems as []).find((e: any) => e.id === item.id) ||
        item.excludeFlag
      );
    },
    selectItem(item: any): boolean {
      this.$emit("onChange", item);
      if (
        !this.selectedItems.includes(item as never) &&
        this.selectedItems.length < this.limitNumber
      ) {
        this.selectedItems.push(item);
        this.$emit("onUpdate", this.selectedItems);
        return true;
      } else return false;
    },
    removeItem(item: any): void {
      this.itemRemoving = true;
      const index = this.selectedItems.indexOf(item as never);
      this.selectedItems.splice(index, 1);
      this.$emit("onUpdate", this.selectedItems);
    },
    createTag(): void {
      if (this.searchQuery.length > 0 && this.isEnterToCreateTag) {
        let item;
        if (this.createUserWithTag)
          item = {
            email: this.searchQuery,
            tag: this.t("contract.placeholderTag"),
          };
        else
          item = {
            email: this.searchQuery,
          };

        if (this.selectItem(item)) {
          this.searchQuery = "";
        }
      }
    },
    searchQueryChange(value: any) {
      this.$emit(
        "onInput",
        this.searchQuery + (value.isComposing ? value?.data ?? "" : "")
      );
      this.$emit("onSearchChange", this.searchQuery);
    },
    changePermission(id: any) {
      this.selectedPermission = id;
      this.$emit("onChangePermission", id);
    },
    clickOutFocusedBox(e: MouseEvent) {
      if (this.itemRemoving) {
        this.itemRemoving = false;
        return;
      }
      if (!(this.$refs.group as any)?.contains(e.target)) {
        this.inputFocused = false;
      }
    },
  },
  mounted() {
    window.addEventListener("click", this.clickOutFocusedBox);
  },
  deactivated() {
    window.removeEventListener("click", this.clickOutFocusedBox);
  },
});


import { defineComponent, reactive, toRefs, ref } from "vue";
import Popup from "@/components/popups/Popup.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import { useForm } from "vee-validate";
import useFormHandler from "@/lib/compositional-logic/useFormHandler";
import ValidationPasswordField from "@/components/atomics/ValidationPasswordField.vue";
import errorsText from "@/locales/ja/errors";
import { hasNumber, hasAlphabet } from "@/lib/utility/stringUtil";

import {
  RepositoryFactory,
  MemberRepository,
  AccountRepository,
} from "@/lib/https";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "UpdatePasswordPopup",
  components: {
    FlatButton,
    Popup,
    ValidationPasswordField,
  },
  props: {
    id: { type: String, required: true },
    showPopup: { type: Boolean, default: false },
    popupTitle: { type: String, required: true },
    isAccount: { type: Boolean, default: false },
    noPassword: {type: Boolean, default:false },
  },
  emits: ["hideEditPopup", "handleSubmitChangeField"],
  setup(props, context) {
    const { t } = useI18n();
    const state = reactive({
      id: props.id,
      password: "",
      oldPassword: "",
      retypePassword: "",
    });

    const { updatePassword } =
      RepositoryFactory.getRepository<MemberRepository>(MemberRepository);

    const { updateAccountPassword } =
      RepositoryFactory.getRepository<AccountRepository>(AccountRepository);

    const hideEditPopup = () => {
      context.emit("hideEditPopup");
    };

    const submit = async function () {
      if (state.password != "" && props.isAccount == false) {
        const formData = new FormData();
        formData.append("password", state.password);
        const response = await updatePassword(state.id, formData);
        if (response) {
          hideEditPopup();
        }
      }
    };

    const form = useForm({
      initialValues: {
        oldPassword: "",
        password: "",
        retypePassword: "",
      },
    });
    const { formHandle, formValues } = useFormHandler(
      form,
      props.isAccount == false ? submit : updateAccountPassword
    );
    const changePassword = async function () {
      if (form.values.password) {
        //validate password
        if (
          !hasAlphabet(form.values.password) ||
          !hasNumber(form.values.password) ||
          form.values.password.length < 8
        ) {
          form.setErrors({
            password: errorsText.invalidPassword,
          });
          return;
        }
      }
      if (state.password != state.retypePassword) {
        form.setErrors({ retypePassword: t("errors.passwordDosentMacth") });
        return;
      }
      const response = await formHandle().catch((e) => {
        form.setErrors({ oldPassword: t("errors.wrongPassword") });
        return;
      });
      if (response) {
        const requiredResponse = {
          noPassword: false
        };
        context.emit("handleSubmitChangeField", requiredResponse);
        hideEditPopup();
      }
    };

    const submitPassword = async function () {
      //validate password
      if (
        !hasAlphabet(form.values.password) ||
        !hasNumber(form.values.password) ||
        form.values.password.length < 8
      ) {
        form.setErrors({
          password: errorsText.invalidPassword,
        });
        return;
      }
      if (state.password != state.retypePassword) {
        form.setErrors({ retypePassword: t("errors.passwordDosentMacth") });
        return;
      }
      await formHandle()
        .then(() => {
          const requiredResponse = {
            noPassword: false
          };
          context.emit("handleSubmitChangeField", requiredResponse);
        })
        .catch((e) => {
          const requiredResponse = {
            errorMessage: e.data.message,
          };
          context.emit("handleSubmitChangeField", requiredResponse);
        });
    };

    return { ...toRefs(state), submitPassword, hideEditPopup, changePassword };
  },
});
/**
 * @vuese
 * @group Layout
 * Popup layout
 */
